<template>
  <div class="tc-interview">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="interviewBtn" @click="showinterviewBox">
        <img src="../../../assets/images/interview.png" height="40" alt />
      </div>
      <div class="del" @click="delDatas">
        <img src="../../../assets/images/del.png" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="../../../assets/images/refresh.png" alt />
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange1"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="晤谈内容"
          width="600"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="showAllotGameBox(scope.row, 0)"
              >分配</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showAllotGameBox(scope.row, 1)"
              >已分配查询</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showTopicBox(scope.row, 1)"
              >添加题目</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showinterviewBox(scope.row, 1)"
              >查看</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showinterviewBox(scope.row, 2)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="delinterviewClass(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 分类模态框 -->
    <el-dialog
      :title="title"
      :visible.sync="interviewBox"
      :width="interviewBoxWidth"
      cter
      @close="formReset"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="晤谈内容:">
          <el-input v-model="form.title" v-if="!isExamine"></el-input>
          <p v-if="isExamine">{{ form.title }}</p>
        </el-form-item>
        <el-form-item label="晤谈说明:">
          <el-input
            type="textarea"
            v-model="form.desc"
            :rows="3"
            v-if="!isExamine"
          ></el-input>
          <p v-if="isExamine">{{ form.desc }}</p>
        </el-form-item>
      </el-form>
      <!-- 题目表格 -->
      <div class="content" v-if="isdetail">
        <el-table
          v-loading="loading2"
          ref="multipleTable"
          :data="detailData"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          border
          class="tableBox"
          height="400"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="题目名称"
            width="600"
            align="left"
            header-align="center"
          ></el-table-column>
          <el-table-column
            prop="preset_options"
            label="答案选项"
            width="300"
            align="left"
            header-align="center"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="题目类型"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.type | type }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope" v-if="!isExamine">
              <el-button
                size="mini"
                type="plain"
                @click="showTopicBox(scope.row, 0)"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button size="mini" type="plain" @click="delDetail(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="interviewBox = false">取 消</el-button>
        <el-button type="primary" @click="addinterviewClass">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 题目模态框 -->
    <el-dialog
      :title="topicTitle"
      :visible.sync="topicBox"
      width="50%"
      center
      @close="topicformReset"
    >
      <el-form
        ref="topicform"
        :model="topicform"
        label-width="100px"
        size="mini"
      >
        <el-form-item label="晤谈问题内容">
          <el-input v-model="topicform.title"></el-input>
        </el-form-item>
        <el-form-item label="问题类型">
          <el-select v-model="topicform.type">
            <el-option label="文字内容" :value="1"></el-option>
            <el-option label="单选" :value="2"></el-option>
            <el-option label="多选" :value="3"></el-option>
            <el-option label="下拉选择" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转信息">
          <el-input
            type="textarea"
            v-model="topicform.jump_info"
            :rows="4"
            placeholder="示例:
             A:4
             B:8"
          ></el-input>
          <span>说明：如果需要 示例 A:4,B:8 选A跳转到第四题 B跳转到第八题</span>
        </el-form-item>
        <el-form-item label="答案">
          <el-input
            type="textarea"
            v-model="topicform.preset_options"
            :rows="4"
            placeholder="示例:
             A:否
             B:是"
          ></el-input>
          <p>说明：下拉选择、单选框、多选框，每个值换一行</p>
          <p>符号请在英文状态下输入 (严格按照示例格式)</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="topicBox = false">取 消</el-button>
        <el-button type="primary" @click="addTopic(0)">完 成</el-button>
        <el-button type="warning" @click="addTopic(1)" v-if="istopicBtn"
          >继续添加</el-button
        >
      </span>
    </el-dialog>
    <!-- 分配，查询模态框 -->
    <el-dialog
      title="分配晤谈"
      :visible.sync="allotGameBox"
      width="70%"
      center
      @close="userReset"
    >
      <!-- 筛选 -->
      <div class="filtrate">
        <div class="header-title">
          <p>筛选</p>
        </div>
        <div class="header-from">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            size="mini"
            ref="formInline"
          >
            <el-form-item label="部门" prop="bumen">
              <el-select v-model="formInline.bumen">
                <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="姓名" prop="user">
              <el-input v-model="formInline.user"></el-input>
            </el-form-item>
            <el-form-item label="用户编号" prop="account">
              <el-input v-model="formInline.account"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit()">查询</el-button>
              <el-button @click="resetForm('formInline')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table
          :data="userList"
          tooltip-effect="dark"
          style="width: 100%"
          max-height="570"
          ref="multipleTable3"
          @selection-change="handleSelectionChange2"
          stripe
          border
          class="tableBox"
          v-loading="userloading"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="account"
            label="用户编号"
            width="230"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="real_name"
            label="姓名"
            width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="department_name"
            label="部门名称"
            width="250"
            align="center"
            v-if="userBoxIndex == 0"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="部门名称"
            width="250"
            align="center"
            v-else
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.sex | sexFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.age | ageFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="show_result"
            label="用户是否查看"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.show_result"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                v-if="!isquery"
              ></el-switch>
              <el-switch
                v-model="scope.row.show_result"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                v-if="isquery"
                @change="allotEdit(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="allot(scope.row)" v-if="!isquery"
                >分配给用户</el-button
              >
              <el-button size="mini" @click="allotDel(scope.row)" v-if="isquery"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 页码 -->
      <div class="page" v-if="showUserPage">
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :page-size="userpageSize"
          :page-sizes="[10, 30, 50, 100, 200, 400]"
          layout="total,sizes, prev, pager, next, jumper"
          :total="usertotal"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allotGameBox = false" type="primary" plain
          >关 闭</el-button
        >
        <!-- <el-button type="primary" @click="allot">批量分配</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getInterviewClassifyList,
  createdInterviewClassify,
  updateInterviewClassify,
  deleteInterviewClassify,
  getInterviewDetail,
  createdInterviewDetail,
  deleteInterviewDetail,
  updateInterviewDetail,
  loadUserData,
  getInterviewUser,
  getInterviewUsers,
  dltInterviewUser,
} from "@/api/evaluate.js";
import { loadDepartmentData } from "@/api/comm.js";
import { log } from "util";
import moment from "moment";
import { lchown } from "fs";
export default {
  inject: ["reload"],
  data() {
    return {
      loading: true,
      loading2: true,
      // 分类
      tableData: [],
      // 题目详情
      detailData: [],
      // 页码
      page: 1,
      size: 15,
      total: 0,
      form: {
        title: "",
        desc: "",
        id: 0,
      },
      topicform: {
        id: 0,
        jump_info: "",
        title: "",
        type: 2,
        preset_options: "",
      },
      topicBox: false, // 题目模态框的显示
      interviewBox: false, // 分类模态框的显示
      isExamine: false, // 控制查看 / 编辑 显示的不同
      isEdit: 1, // 控制添加 / 编辑 api
      title: "添加晤谈分类", // 分类模态框标题
      isdetail: false, // 控制题目表格
      interviewBoxWidth: "30%", // 分类模态框宽度
      utopicid: 0, // 当前选择题目id
      topicTitle: "添加题目", // 添加题目模态框标题
      istopicBtn: true, //控制 添加模态框  编辑 、 添加
      multipleSelection: [],
      // *********************
      allotGameBox: false,
      userList: [],
      userpage: 1,
      userpageSize: 10,
      usertotal: 0,
      userloading: true,
      formInline: {
        bumen: "",
        user: "",
        account: "",
      },
      departmentList: [],
      showUserPage: true,
      userInfoData: [],
      userInfoloading: true,
      multipleSelection3: [],
      allotWid: "",
      userBoxIndex: "", //  当前是 分配 还是查看分配 框
      isquery: false,
    };
  },
  created() {
    this.loadListData();

    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
  },
  methods: {
    handleSelectionChange1(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.page = val;
      this.loadListData(val);
    },
    handleSelectionChange() {},
    // 获取晤谈列表
    loadListData(page) {
      let config = {
        page: page || this.page,
        size: this.size,
      };
      getInterviewClassifyList(config).then((res) => {
        if (res.code === 400200) {
          //console.log(res);
          this.total = parseInt(res.data.count);
          this.tableData = res.data.data;
          this.loading = false;
        }
      });
    },
    // 显示晤谈分类  / 编辑
    showinterviewBox(row, val) {
      this.loading2 = true;
      this.title = "添加晤谈分类";
      this.interviewBox = true;
      this.isEdit = 1;
      this.interviewBoxWidth = "30%";
      if (row.id && val === 2) {
        this.interviewBoxWidth = "80%";
        this.isdetail = true;
        this.isEdit = 0;
        this.title = "编辑晤谈分类";
        let { title, desc, id } = row;
        this.form.title = title;
        this.form.desc = desc;
        this.form.id = id;
        // 获取题目详情
        getInterviewDetail({ id }).then((res) => {
          if (res.code == 400200) {
            this.detailData = res.data;
            this.loading2 = false;
          }
        });
      }
      if (val === 1) {
        this.interviewBoxWidth = "80%";
        this.isdetail = true;
        this.isEdit = 2;
        this.isExamine = true;
        this.title = "查看晤谈分类详情";
        let { title, desc, id } = row;
        this.form.title = title;
        this.form.desc = desc;
        this.form.id = id;
        // 获取题目详情
        getInterviewDetail({ id }).then((res) => {
          if (res.code === 400200) {
            this.detailData = res.data;
            this.loading2 = false;
          }
        });
      }
    },
    // 添加 / 编辑 晤谈分类
    addinterviewClass(val) {
      if (this.isEdit === 1) {
        createdInterviewClassify(this.form).then((res) => {
          if (res.code === 400200) {
            this.$message({
              showClose: true,
              message: "恭喜你，添加成功",
              type: "success",
            });
            this.interviewBox = false;
            this.loadListData();
          }
        });
      } else if (this.isEdit === 0) {
        updateInterviewClassify(this.form).then((res) => {
          if (res.code === 400200) {
            this.$message({
              showClose: true,
              message: "恭喜你，修改成功",
              type: "success",
            });
            this.interviewBox = false;
            this.loadListData();
          }
        });
      } else {
        this.interviewBox = false;
      }
    },
    // 删除分类
    delinterviewClass(row) {
      deleteInterviewClassify({ id: row.id }).then((res) => {
        if (res.code === 400200) {
          this.$message({
            showClose: true,
            message: "恭喜你,删除成功",
            type: "success",
          });
          this.loadListData();
        }
      });
    },
    // 关闭模态框重置
    formReset() {
      this.detailData = [];
      this.form.title = "";
      this.form.desc = "";
      this.isExamine = false;
      this.isdetail = false;
    },
    // 显示添加题目模态框  /  编辑
    showTopicBox(row, val) {
      this.topicBox = true;
      this.utopicid = row.id;
      if (val === 1) {
        this.istopicBtn = true;
        this.topicTitle = "添加题目";
      } else {
        this.topicform.id = row.id;
        this.istopicBtn = false;
        this.topicTitle = "修改题目";
        this.topicform.title = row.title;
        this.topicform.type = row.type;
        this.topicform.preset_options = row.preset_options.replace(/ /, "\n");
        this.topicform.jump_info = row.jump_info;
      }
    },
    // 添加 题目     / 编辑
    addTopic(val) {
      // 处理数据
      let { jump_info, title, type, preset_options, id } = this.topicform;
      preset_options = preset_options.replace(/\n/g, " ");
      jump_info = jump_info.replace(/\n/, " ");
      let data = {
        jump_info,
        title,
        type,
        preset_options,
        inter_id: this.utopicid,
        id,
      };

      if (this.istopicBtn) {
        createdInterviewDetail(data).then((res) => {
          if (res.code === 400200) {
            if (res.code === 400200) {
              this.$message({
                showClose: true,
                message: "恭喜你，添加成功",
                type: "success",
              });
              // 继续添加
              if (val === 1) {
                this.topicformReset();
              }
              // 完成
              if (val === 0) {
                this.topicBox = false;
              }
            }
          }
        });
      } else {
        // 修改api
        updateInterviewDetail(data).then((res) => {
          if (res.code === 400200) {
            this.$message({
              showClose: true,
              message: "恭喜你，添加成功",
              type: "success",
            });
            getInterviewDetail({ id: this.form.id }).then((res) => {
              this.detailData = res.data;
            });
            this.topicBox = false;
          }
        });
      }
    },
    // 重置添加模态框
    topicformReset() {
      this.topicform.jump_info = "";
      this.topicform.title = "";
      this.topicform.type = 2;
      this.topicform.preset_options = "";
    },
    // 删除题目
    delDetail(row) {
      deleteInterviewDetail({ ids: row.id }).then((res) => {
        if (res.code === 400200) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          getInterviewDetail({ id: this.form.id }).then((res) => {
            this.detailData = res.data;
          });
        }
      });
    },
    // 全选删除
    delDatas() {
      // 判断是否有数据
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要删除的数据",
          type: "warning",
        });
        return;
      }
      //  提示 是否删除
      this.$confirm("是否删除已勾选的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = this.multipleSelection;
        })
        .catch(() => {});
    },
    // *************用户列表模态框*******************
    // 用户列表模态框-重置用户分配
    userReset() {
      this.$refs.multipleTable3.clearSelection();
      this.userList = [];
      this.usertotal = 0;
      this.userpage = 1;
      this.userloading = true;
      this.isquery = false;
    },
    // 用户列表模态框-勾选数据
    handleSelectionChange2(val) {
      this.multipleSelection3 = val;
      let p = [];
      this.multipleSelection3.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection3 = p;
      console.log(p);
    },
    // 用户列表模态框-设置当前页面最大显示
    handleSizeChange2(val) {
      this.userloading = true;
      this.userpageSize = val;
      if (this.isquery) {
        this.loadInetusers();
      } else {
        this.loadUsers();
      }
    },
    // 用户列表模态框-页码监听
    handleCurrentChange2(val) {
      this.userloading = true;
      this.userpage = val;
      if (this.isquery) {
        this.loadInetusers();
      } else {
        this.loadUsers();
      }
    },
    // 显示用户列表模态框
    showAllotGameBox(row, val) {
      this.allotWid = row.id; //当前打开时的 晤谈 id
      this.allotGameBox = true;
      this.userBoxIndex = val;
      if (val == 1) {
        this.isquery = true;
        this.loadInetusers();
      } else {
        this.loadUsers();
      }
    },
    // 获取所有用户列表
    loadUsers(val) {
      this.userList = [];
      let data = {
        page: this.userpage,
        pagesize: this.userpageSize,
        account: this.formInline.account,
        real_name: this.formInline.user,
        department_id: this.formInline.bumen,
      };
      // 发送数据
      loadUserData(data).then((res) => {
        if (res.code == 400200) {
          let tmp = res.data;
          this.usertotal = res.count;
          this.userloading = false;
          // 添加字段 show_result 标示
          tmp.forEach((v) => {
            v.show_result = 0;
            this.userList.push(v);
          });
        }
      });
    },
    // 获取 某个晤谈下 的 用户列表
    loadInetusers() {
      let data = {
        page: this.userpage,
        pagesize: this.userpageSize,
        account: this.formInline.account,
        real_name: this.formInline.user,
        department_id: this.formInline.bumen,
        iid: this.allotWid,
      };
      getInterviewUsers(data).then((res) => {
        if (res.code == 400200) {
          this.userList = res.data;
          this.userloading = false;
          this.usertotal = res.count;
        }
      });
    },
    // 分配查询
    onSubmit() {
      this.userloading = true;
      this.showUserPage = false;
      if (this.userBoxIndex == 1) {
        this.loadInetusers();
        console.log(this.userList);
      } else {
        this.loadUsers(1);
      }
      this.$nextTick(() => {
        this.showUserPage = true;
      });
    },
    // 重置按钮
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 分配给用户
    allot(row) {
      console.log(row);
      let uid = row.id;
      let data = {
        user_id: uid,
        interview_id: this.allotWid,
        show_result: row.show_result,
      };
      getInterviewUser(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "恭喜你，分配成功",
            type: "success",
          });
        }
      });
    },
    // 查看分配-移除
    allotDel(row) {
      dltInterviewUser({ id: row.id }).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "删除成功!",
            type: "success",
          });
          this.userList = this.userList.filter((v, k) => {
            return v.id != row.id;
          });
        }
      });
    },
    // 查看分配-编辑
    allotEdit(row) {
      let data = {
        user_id: row.user_id,
        interview_id: this.allotWid,
        show_result: row.show_result,
      };
      if (row.show_result == 0) {
        data.show_result = 0;
      } else {
        data.show_result = 1;
      }
      getInterviewUser(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "恭喜你，修改成功",
            type: "success",
          });
        }
      });
    },
  },
  filters: {
    type(val) {
      let arr = ["文字内容", "单选", "多选", "下拉菜单"];
      return arr[val - 1];
    },
    // 性别过滤器
    sexFilter(val) {
      return val == 0 ? "女" : "男";
    },
    // 年龄过滤器
    ageFilter(val) {
      let text = moment(val, "YYYY-MM-DD").fromNow();
      let age = parseInt(text, 10);
      if (isNaN(age)) {
        age = "0";
      }
      return age;
    },
  },
};
</script>

<style lang="less">
.tc-interview {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .interviewBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 745px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 模态框
  .el-dialog__wrapper {
    .content {
      min-height: 0;
    }
  }
  .page {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
    }
  }
}
</style>